exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-reklam-3-tsx": () => import("./../../../src/pages/5-reklam-3.tsx" /* webpackChunkName: "component---src-pages-5-reklam-3-tsx" */),
  "component---src-pages-5-reklam-tsx": () => import("./../../../src/pages/5-reklam.tsx" /* webpackChunkName: "component---src-pages-5-reklam-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-adsy-chill-tsx": () => import("./../../../src/pages/adsy-chill.tsx" /* webpackChunkName: "component---src-pages-adsy-chill-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-ebook-tsx": () => import("./../../../src/pages/ebook.tsx" /* webpackChunkName: "component---src-pages-ebook-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-materials-tsx": () => import("./../../../src/pages/materials.tsx" /* webpackChunkName: "component---src-pages-materials-tsx" */),
  "component---src-pages-meta-ads-mentoring-tsx": () => import("./../../../src/pages/meta-ads-mentoring.tsx" /* webpackChunkName: "component---src-pages-meta-ads-mentoring-tsx" */),
  "component---src-pages-ogarnij-swoje-adsy-2-tsx": () => import("./../../../src/pages/ogarnij-swoje-adsy-2.tsx" /* webpackChunkName: "component---src-pages-ogarnij-swoje-adsy-2-tsx" */),
  "component---src-pages-ogarnij-swoje-adsy-tsx": () => import("./../../../src/pages/ogarnij-swoje-adsy.tsx" /* webpackChunkName: "component---src-pages-ogarnij-swoje-adsy-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-powtorka-webinaru-archiwalna-tsx": () => import("./../../../src/pages/powtorka-webinaru-archiwalna.tsx" /* webpackChunkName: "component---src-pages-powtorka-webinaru-archiwalna-tsx" */),
  "component---src-pages-powtorka-webinaru-tsx": () => import("./../../../src/pages/powtorka-webinaru.tsx" /* webpackChunkName: "component---src-pages-powtorka-webinaru-tsx" */),
  "component---src-pages-puzzle-tsx": () => import("./../../../src/pages/puzzle.tsx" /* webpackChunkName: "component---src-pages-puzzle-tsx" */),
  "component---src-pages-strona-archiwalna-tsx": () => import("./../../../src/pages/strona-archiwalna.tsx" /* webpackChunkName: "component---src-pages-strona-archiwalna-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-2-tsx": () => import("./../../../src/pages/thank2.tsx" /* webpackChunkName: "component---src-pages-thank-2-tsx" */),
  "component---src-pages-thank-tsx": () => import("./../../../src/pages/thank.tsx" /* webpackChunkName: "component---src-pages-thank-tsx" */),
  "component---src-pages-wakacje-tsx": () => import("./../../../src/pages/wakacje.tsx" /* webpackChunkName: "component---src-pages-wakacje-tsx" */),
  "component---src-pages-warsztat-lejek-tsx": () => import("./../../../src/pages/warsztat-lejek.tsx" /* webpackChunkName: "component---src-pages-warsztat-lejek-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-podcast-podcast-tsx": () => import("./../../../src/templates/podcast/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-podcast-tsx" */)
}

